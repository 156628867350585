<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex items-center mx-2">
          <div class="border-r border-romanSilver h-6 flex">
            <BackButton label="Back" @onClick="$router.back()" variant="secondary" />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-3">Compensation</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template v-if="hasModuleAccess">
        <template v-if="marketRateSettings">
          <div class="flex flex-col justify-start items-start gap-5 px-5 pt-10">
            <template>
              <div>
                <span class="font-semibold text-base leading-5 text-darkPurple">
                  Select parameter to adjust
                </span>
                <RadioButton
                  space-between="mr-2 ml-5 text-jet text-base font-normal leading-5"
                  v-model="revisionParameter"
                  default="'reference_market_rate'"
                  :options="[
                    { name: 'Market Rate', value: 'market_rate' },
                    { name: 'Policy Percentage', value: 'policy_percentage'},
                  ]"
                />
                <div class="flex justify-start items-center gap-3">
                  <span>Adjust by</span>
                  <Select
                    v-if="revisionParameter === 'market_rate'"
                    placeholder="Select"
                    class="w-32 text-base"
                    v-model="adjustBy"
                    :options="[
                      { name: '(₦) Amount', id: 'amount'},
                      { name: '(%) Increase', id: 'percent_increase'},
                      { name: '(%) Decrease', id: 'percent_decrease'},
                    ]"
                  />
                  <TextInput
                    type="number"
                    placeholder="Input"
                    class="w-34 text-base"
                    v-model.number="reviseFactor"
                  />
                </div>
              </div>
            </template>
            <template>
              <template v-if="revisionParameter === 'market_rate'">
                <Table
                  :headers="withMarketRate"
                  :items="paygrades"
                  :has-number="false"
                  class="w-full"
                >
                  <template v-slot:item="{ item }">
                    <div v-if="item.payGradeId" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">
                        {{ item.data.payGradeId }}
                      </span>
                    </div>
                    <div v-if="item.currentMarketRate">
                      <span class="text-darkPurple font-normal text-sm leading-5">
                        {{ convertToCurrency(item.data.currentMarketRate) }}
                      </span>
                    </div>
                    <div v-if="item.referenceMarketRate">
                      <span class="text-darkPurple font-normal text-sm leading-5">
                        {{ computedAdjustedPayGrade(item.data.currentMarketRate) }}
                      </span>
                    </div>
                  </template>
                </Table>
              </template>
              <template v-if="revisionParameter === 'policy_percentage'">
                <Table
                  :headers="withMarketPolicy"
                  :items="paygrades"
                  :has-number="false"
                  class="w-full"
                >
                  <template v-slot:item="{ item }">
                    <div v-if="item.payGradeId" class="font-normal text-sm leading-5">
                      <span class="text-darkPurple">
                        {{ item.data.payGradeId }}
                      </span>
                    </div>
                    <div v-if="item.policyPercent">
                      <span class="text-darkPurple font-normal text-sm leading-5">
                        {{ item.data.policyPercent.toFixed(2) }}%
                      </span>
                    </div>
                    <div v-if="item.referenceMarketRate">
                      <span class="text-darkPurple font-normal text-sm leading-5">
                        {{ adjustedPayGradeByPercent(item.data.policyPercent) }}%
                      </span>
                    </div>
                  </template>
                </Table>
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col justify-start items-start gap-5 px-5 pt-10">
            <template>
              <div class="flex justify-start items-center gap-3">
                <span>Adjust by</span>
                <Select
                  placeholder="Select"
                  class="w-32 text-base"
                  v-model="adjustBy"
                  :options="[
                    { name: '(₦) Amount', id: 'amount'},
                    { name: '(%) Increase', id: 'percent_increase'},
                    { name: '(%) Decrease', id: 'percent_decrease'},
                  ]"
                />
                <TextInput
                  type="number"
                  min="0"
                  placeholder="Input"
                  class="w-34 text-base"
                  v-model.number="reviseFactor"
                />
              </div>
            </template>
            <template>
              <Table
                :headers="withoutMarketRate"
                :items="paygrades"
                :has-number="false"
                class="w-full"
              >
                <template v-slot:item="{ item }">
                  <div v-if="item.payGradeId" class="font-normal text-sm leading-5 tracking-widest">
                    <span class="text-darkPurple">
                      {{ item.data.payGradeId }}
                    </span>
                  </div>
                  <div v-if="item.rangeMidPoint">
                    <span class="text-darkPurple font-normal text-sm leading-5">
                      {{ convertToCurrency(item.data.rangeMidPoint) }}
                    </span>
                  </div>
                  <div v-if="item.rangeSpreadId">
                    <span class="text-darkPurple font-normal text-sm leading-5">
                      {{ computedAdjustedPayGrade(item.data.rangeMidPoint) }}
                    </span>
                  </div>
                </template>
              </Table>
            </template>
          </div>
        </template>
        <template>
          <div class="absolute -bottom-0 mx-5 mb-2">
            <Button
              label="Save"
              class="button-class
              button-primary btn-bg"
              @onClick="onSubmit"
            />
            <Button
              label="Cancel"
              class="button-class
              button-default"
              @onClick="$router.back()"
            />
          </div>
        </template>
      </template>
      <div v-else>
        <ErrorComponent />
      </div>
    </template>
  </div>
</template>

<script>
  import * as _ from 'lodash';
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Button from "@scelloo/cloudenly-ui/src/components/button";
  import Select from "@scelloo/cloudenly-ui/src/components/select";
  import TextInput from "@scelloo/cloudenly-ui/src/components/text";
  import ErrorComponent from "@/modules/Admin/error403"

  export default {
    name: 'AdjustPayGrade',
    components: {
      Breadcrumb,
      BackButton,
      RadioButton,
      Button,
      Table,
      Select,
      TextInput,
      ErrorComponent,
    },
    data(){
      return {
        isLoading: true,
        hasModuleAccess: false,
        isFetching: true,
        reviseFactor: '',
        marketRateSettings: null,
        adjustBy: '',
        revisionParameter: 'market_rate',
        paygrades: [],
        breadcrumbs: [
          {
            disabled: false,
            text: "Compensation",
            href: "compensation",
            id: "Compensation"
          },
          {
            disabled: false,
            text: "Pay Insight",
            href: "CompensationPayInsight",
            id: "CompensationPayInsight"
          },
          {
            disabled: false,
            text: "Adjust Pay Grade",
            href: "AdjustPayGrade",
            id: "AdjustPayGrade"
          }
        ],
        withMarketRate: [
          { title: "pay grade", value: "payGradeId" },
          { title: "current market rate", value: "currentMarketRate" },
          { title: "new market rate", value: "referenceMarketRate" },
        ],
        withMarketPolicy: [
          { title: "pay grade", value: "payGradeId" },
          { title: "current policy percentage", value: "policyPercent" },
          { title: "new policy percentage", value: "referenceMarketRate" },
        ],
        withoutMarketRate: [
          { title: "pay grade", value: "payGradeId" },
          { title: "Current Range Midpoint", value: "rangeMidPoint" },
          { title: "New Range Midpoint", value: "rangeSpreadId" },
        ],
      }
    },
    methods: {
      toggleViewPayGrade(){
        this.$refs.viewPayGrade.toggle();
      },
      computedAdjustedPayGrade(value){
        if(_.isEqual(this.adjustBy, 'amount')){
          return this.convertToCurrency(value + this.reviseFactor)
        } if(_.isEqual(this.adjustBy, 'percent_increase')){
          return this.convertToCurrency(Math.fround(((Math.abs(this.reviseFactor) / 100) * value) + value))
        } if(_.isEqual(this.adjustBy, 'percent_decrease')){
          return this.convertToCurrency(Math.fround(value - ((Math.abs(this.reviseFactor) / 100) * value)))
        } return this.convertToCurrency(value)
      },
      adjustedPayGradeByPercent(value){
        return Math.fround(((this.reviseFactor / 100) * value) + value)?.toFixed(2)
      },
      onSubmit(){
        if(!this.reviseFactor){
          const message = "Revise factor cannot be empty"
          return this.$toasted.error(message, { duration: 3000 })
        }
        this.isLoading = true
        const paygradesId = this.paygrades.map(paygrade => ({
          paygradeId: paygrade.id
        }))

        const payload = {
          orgId: this.$orgId,
          payGrades: paygradesId,
          adjustBy: this.adjustBy,
          reviseBy: this.$AuthUser.id,
          reviseFactor: this.reviseFactor,
          revisionParameter: this.revisionParameter,
        }

        return this.$_postAdjustPayGrade(payload).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      },
      async getOrgMarketRateSettings(){
        await this.$_getOrgMarketRateSettings().then(({ data }) => {
          this.marketRateSettings = data?.market?.referenceMarketRate
        })
      },
      async getOrgPayGradesPayInsights(){
        await this.$_getOrgPayInsights({}).then(({ data }) => {
          this.paygrades = data.grades?.filter(({ paygrade }) => (
            paygrade?.paygrade?.referenceMarketRate === this.marketRateSettings
          )).map((insight) => ({ ...insight, ...insight.paygrade.paygrade }))
        })
      },
      async getPayGradePayInsightById(params){
        await this.$_getPayGradePayInsightById(params).then(({ data }) => {
          this.paygrades.push(data.employeePaygrades.paygrade.paygrade)
        })
      },
      async bootstrapModule(){
        const paygradeId = this.$route.params.id
        await this.getOrgMarketRateSettings()
        if (this.$route.params.id) await this.getPayGradePayInsightById({ paygradeId })
        else await this.getOrgPayGradesPayInsights()
      }
    },
    async created(){
      this.isLoading = true
      try {
        await this.$handlePrivilege('payInsight', 'adjustPayGrade')
        this.hasModuleAccess = true
        this.bootstrapModule()
        this.isLoading = false
      } catch (error) {
        this.hasModuleAccess = false
        this.isLoading = false
      }
    },
    watch: {
      reviseFactor(value){
        if(this.adjustBy !== 'amount'){
          this.reviseFactor = Math.abs(value)
        }
      }
    }
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
 .button-class {
   padding: 10px 40px !important;
 }
 .button-default {
   background: transparent !important;
   color: #321C3B !important;
 }

 .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>
